.registration-borderbg.MuiPaper-root{
    background: #FFFFFF;
    border: 1px solid #CBE2D9;
    border-radius: 10px;
}
.registration-borderbg h2.MuiTypography-root{
    border-bottom: 1px solid #CBE2D9;
    padding:20px;
}
.registration-fill{padding:20px;}
.fullbox {
    width: 48%;
}

.registrationdetailsmodal-view{
    margin:30px 0;
}
.labal-box {
    width: 50%;
    gap:4px;
}
.labal-box label{
    color: rgba(73, 74, 76, 0.6);
}
.open-camera{
    text-align:center;
}
.open-camera .MuiTypography-root{font-weight:700;font-size:22px;line-height:33px;}
.camera-box{
  margin-top:20px; 
}
.camera-box .MuiTypography-root{color:#04B983;margin-top:8px;}
.camera-box svg{width:30px; height:30px;color:#04B983;}


@media screen and (max-width:620px) {
    .labal-box,
    .fullbox {
        width: 100%;
    }
    .labalbox-main{flex-direction:column;}
    .registrationdetailsmodal-view .gap20,
    .registrationdetailsmodal-view.gap20{gap:10px;}
}