.snackbar .MuiPaper-root {
    gap: 10px;
    margin:0;
    align-items: center;
    padding:10px 15px;
    background-color: #E2374B;
    line-height: 25px;
}

.snackbar .MuiAlert-action,
.snackbar .MuiButtonBase-root,
.snackbar .MuiAlert-message,
.snackbar .MuiAlert-icon {
    padding: 0;
    margin: 0;
    color: #fff !important;
    font-size: 16px;
}