.splash-screen {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
}
.splash-screen svg{width:456px; height:123px;}
@media screen and (max-width:767px) {
    .splash-screen svg {
        width: 220px;
        height: 53px;
    }
}