.table-main {
    background: #FFFFFF;
    border: 1px solid #CBE2D9;
    border-radius: 10px;
    padding: 0 20px;
}

.table-main .MuiTableRow-root:nth-of-type(odd) {
    background: none;
}

.table-main .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root {
    padding-bottom: 20px;
}
.table-main .MuiTableBody-root .MuiButton-text{
    padding: 3px 0;
    font-size: 15px;
    font-weight: 400;
    width: 74px;
}
.table-main .MuiTableBody-root .MuiSvgIcon-root{
    font-size:18px;
}
.table-main .MuiTableBody-root .MuiButton-text.basic{
    background: #FFF2EE;   
    color: #F35800;   
}
.table-main .MuiTableBody-root .MuiButton-text.business{
    background: #F5F2FF;   
    color: #1A59DF; 
}
.table-main .MuiTableBody-root .MuiButton-text.standard{
    color: #009210; 
    background: #E9FFEB;
}
.table-main .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
  background: white;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display:block;
}

.userdatashow .MuiTableCell-root.MuiTableCell-head{
font-size:15px;
}
.userdatashow .MuiTableCell-root.MuiTableCell-body{font-size:14px;padding-right:10px;}
.userdatashow .MuiTableCell-root.MuiTableCell-body:first-child{padding-left:0;}

.invoicenumber{margin:24px 0;}
.billfrom-main {
    border-bottom: 1px solid #494a4c4f;
    padding-bottom: 35px;
    margin-bottom: 35px;
}
.billfrom-box{gap:8px;width:42%;}
.billfrom-box h6{font-weight:700;}
.order-details{
    border-bottom: 1px solid #494a4c4f;
    padding-bottom:40px;
    margin-bottom:20px; 
}
.searchbar{margin:20px 0;}

@media screen and (max-width:767px) {
    .table-main .MuiTableBody-root .MuiButton-text {
        font-size: 10px;
        width: 50px;
        min-width: inherit;
    }
    .billfrom-main {
        gap: 15px;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    .billfrom-box {
        width: 180px;
        gap:0px;
    }
    .invoicenumber {
        gap: 10px;
    }
    .invoicebox span,
    .order-details h4.MuiTypography-root,
    .billfrom-box h6.MuiTypography-root {font-size:13px;}
    .paymentcard-detailes .totaltex {
        width:51%;
    }
    .paymentcard-detailes .totaltex li strong,
    .order-details .invoicebox strong{
        font-size: 13px;
        line-height:20px;
    }
    .invoicenumber .MuiTypography-root,
    .billfrom-box .MuiTypography-root,
    .paymentcard-detailes span{font-size:10px;}
    .invoiceheader svg{
        width:120px;
        height:30px;
    }
    .subscribersList  .MuiStack-root .MuiTypography-root{display:none;}
    .table-main{padding:0 10px;}
    .paymentcard-detailes .gap40{gap:30px;}
    .table-main .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
        width:170px;
    }
    .table-main .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(1){
        width: 110px;
        padding-right:10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;float:left;
    }
    .table-main .MuiTableCell-root{
        font-size:10px !important; 
     }
     .invoiceheader h6.MuiTypography-root{font-size:25px !important;line-height: 30px!important;}
     .table-main .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-child(1),
     .table-main .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(1){
         display:none;
     }
}

@media screen and (max-width:500px) {
    .table-main .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(2) {
        width:110px;
    }
    .table-main .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root:nth-child(1),
    .table-main .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:nth-child(1){
        display:none;
    }
    .billfrom-box{width:50%;gap:4px;}
    .billfrom-main {
        gap: 6px;
        padding-bottom: 25px;
        margin-bottom: 25px;
    }
    .invoicenumber {
        margin: 10px 0;
        gap: 10px;
    }
    .paymentcard-detailes{
        gap:10px;
    }
    .paymentcard-detailes .totaltex {
        width:40%;
    }   
    .invoicenumber span,
    .order-details .invoicebox span,
    .order-details h4.MuiTypography-root,
    .billfrom-box h6.MuiTypography-root {
        font-size: 9px;
        line-height: 16px;
    }
    .paymentcard-detailes .totaltex li strong,
    .billfrom-box .MuiTypography-root, .paymentcard-detailes span {
        font-size:9px;
        line-height: 16px;
    }
    .order-details{padding-bottom: 20px;}

}