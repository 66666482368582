.select-plans {
    background: #FFFFFF;
    border: 1px solid #CBE2D9;
    border-radius: 10px;
    padding: 20px;
}

.view-plans {
    text-align: center;
    gap: 30px;
}

.plansbox {
    background: #FFFFFF;
    border: 0.5px solid #04B983;
    border-radius: 10px;
    padding: 20px 16px;
    position: relative;
    width: 26%;
    cursor: pointer;
}

.plansbox.active {
    border: 2px solid #04B983;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}

.plansbox.active::before {
    content: "";
    position: absolute;
    top: 0;
    height: 7px;
    width: 100%;
    background: #04B983;
    left: 0;
    border-radius: 10px 10px 0px 0px;
}

.plansbox h5 {
    position: absolute;
    top: -18px;
    margin: auto;
    left: 0;
    right: 0;
    width: 90px;
}

.plansbox hr {
    margin: 20px 0;
    border-color: #04B983;
}

.payment-method-main {
    margin-top: 24px;
}

.payment-box {
    width: 120px;
    border: 1px solid #494A4C;
    border-radius: 10px;
    padding: 20px;
    gap: 5px;
    cursor: pointer;
}
.payment-box svg {
    fill: #494A4C;
}
.payment-box.addactive {
    border-color: #04B983;
}
.payment-box.addactive .MuiTypography-root {
    color: #04B983;
}
.payment-box.addactive svg {
    fill: #04B983;
}
.invoice-main{
    border-bottom:1px dashed #494a4c4f;
    padding-bottom:20px;
    margin-bottom:20px;
    margin-top:24px;
}
.invoicebox{gap:8px;}
.invoicebox strong{
    font-weight: 700;
    font-size: 20px;
    line-height:30px;
}

.totaltex{
    display: flex;
    flex-direction: column;
    width: 30%;
    float: right;
}
.totaltex li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 8px 0;
    justify-content: space-between;
    display: flex;
}
.totaltex li:first-child{padding-top:0;}
.totaltex li:last-child{border-bottom:none;padding-bottom:0;}
.totaltex li strong{
    color: #04B983;
    font-weight: 700;
    font-size: 18px;
    line-height:27px;
}
.invoicesend-btn.MuiButtonBase-root{margin:50px 0;}
@media screen and (max-width:620px) {
    .view-plans{flex-flow: wrap;}
    .plansbox{width:100%;}
    .payment-box{padding:15px;width:17%;}
    .payment-box svg {
        width: 40px;
        height: 30px;
    }
    .payment-method-main {
        flex-flow: wrap;
    }
    .totaltex{width:100%; float:left;}
    .invoicebox strong {
        font-size: 15px;
        line-height:20px;
    }
}