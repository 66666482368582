.error-main {
    text-align: center;
    height: 100vh;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.error-main p{width:30%;}
.title1{font-size:45px; line-height:54px; margin:0;}
.btnhome{
    background: none;
    padding: 15px;
    transition: 0.5s;
    text-transform: uppercase;
    color: rgb(4, 185, 131);
    border: solid 1px rgb(4, 185, 131);
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1em;
    cursor: pointer;
    position: relative;
    text-decoration:none;
    display: inline-block;
}

@media only screen and (max-width:767px) {
    .error-main p{width:auto;}    
}