.loginbox-main{height:100vh; text-align:center;}
.loginbox{
    padding:50px;background: #FFFFFF;
    border: 1px solid #CBE2D9;border-radius: 10px;
    width:490px;
    margin:auto;
}    
.loginnow{
    background-color: #04B983 !important;
    font-size: 16px !important;
    color: #FFFFFF !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
    padding: 8px 22px !important;
    line-height: 24px !important;
    letter-spacing: 0 !important;
    border-radius:4px !important;
    border:none!important;
    cursor: pointer;
}
@media screen and (max-width:767px) {
    .loginbox{
        width:auto;
        padding:20px;
    }
}